import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from '../../components/Link';
import { useTranslation } from 'react-i18next';
import { mediaQueries } from '../../utils/mediaQueries';

import { getImages } from '../../utils/common';

const Container = styled(Layout)`
  max-width: 100%;
`;

const Image1 = styled(Img)`
  max-width: 100%;
`;

const Button = styled(Link)`
  position: absolute;
  background-color: red;
  top: 72.5%;
  left: 50%;
  width: 21%;
  height: 2.5%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  ${mediaQueries('md')} {
    top:78%;
    width:25%;
  }
`;


const Button2 = styled(Link)`
  position: absolute;
  background-color: red;
  top: 87.5%;
  left: 50%;
  width: 21%;
	height: 2.5%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  ${mediaQueries('md')} {
    top:90%;
    width:25%;
  }
`;

const Button3 = styled(Link)`
  position: absolute;
  background-color: red;
  top: 96%;
  left: 29%;
  width: 10%;
  height: 1%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  ${mediaQueries('md')} {
    top:97%;
    left: 29%;
    width:10%;
  }
`;

const Button_EN = styled(Link)`
  position: absolute;
  background-color: red;
  top: 74%;
  left: 50%;
  width: 21%;
  height: 2.5%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  ${mediaQueries('md')} {
    top:77%;
    width:25%;
  }
`;

const Button2_EN = styled(Link)`
  position: absolute;
  background-color: red;
  top: 87%;
  left: 50%;
  width: 21%;
	height: 2.5%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  ${mediaQueries('md')} {
    top:89%;
    width:25%;
  }
`;

const Button3_EN = styled(Link)`
  position: absolute;
  background-color: red;
  top: 95.5%;
  left: 73%;
  width: 16%;
  height: 1%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  ${mediaQueries('md')} {
    top:96%;
    left: 73%;
    width:16%;
  }
`;

const useImageData = () => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/Recycle" } }) {
        nodes {
          name
          childImageSharp {
            fluid(quality:  90,maxWidth: 980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return allFile.nodes;
};

const Recycle = () => {
  const { t } = useTranslation();
  const images = getImages(useImageData());
  const { isMobileView, locale } = useSelector((state) => state.common);

  const imagePath_mb = locale === 'zh' ? 'Biotherm-Website-Poster-2024' : 'Biotherm-Website-Poster_ENG-2024';

  const imagePath = locale === 'zh' ? 'Biotherm-Website-Poster-2024' : 'Biotherm-Website-Poster_ENG-2024';

  return (
    <Layout padding={false} seoSettings={{ title: t('recycle') }}>
      <div className="position-relative">
        {/* {locale === 'zh' ? (
          <Button to='/store-locator' />
        ) : (
          <Button_EN to='/store-locator' />
        )}
        {locale === 'zh' ? (
          <Button2 to='https://www.vcycle.com.hk/' />
        ) : (
          <Button2_EN to='https://www.vcycle.com.hk/' />
        )}
        {locale === 'zh' ? (
          <Button3 to='/membership' />
        ) : (
          <Button3_EN to='/membership' />
        )} */}

        {isMobileView ? (
          <Image1 fluid={images[imagePath_mb]} />
        ) : (
          <Image1 fluid={images[imagePath]} />
        )}
      </div>
    </Layout>
  );
};

export default Recycle;

